import React from 'react';
//import logo from './logo.svg';
import './App.css';
import {
	BrowserRouter as Router,
	Routes,
	Route,
} from 'react-router-dom';
import Layout from './components/Layout';
import './dotenv'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="*" element={<Layout />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
