import React from 'react';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/dist/css/splide.min.css';
const ButtonClickerNext = () => {
    document.getElementById("splideNext").click();
}
const ButtonClickerPrev = () => {
    document.getElementById("splidePrev").click();
}
const Testimonials = () => {
  return (
    <>
    <Splide
    options={{
        type: 'loop',
        autoplay: true,
        pagination: false
    }}
    aria-label="My Favorite Images" hasTrack={false}>
        <SplideTrack>
            <SplideSlide>
                <div className="testimonialCard">
                    <p className='text'>Simply just works - almost everywhere.
It’s cheaper than having multiple app and paying for minimum balance or monthly subscription - I charge my car mostly at apartment and office using Plugnow.  And I use Plugnow when I am out for long drive too.</p>
                    <div className="cDetails">
                        <p className="uname">Harold Paul E</p>
                        {/* <p className="desig">Founder @Zapp Mobility</p> */}
                    </div>
                </div>
            </SplideSlide>
            <SplideSlide>
                <div className="testimonialCard">
                    <p className='text'>Seamless easy charging everywhere- that is precisely what I wanted to achieve. App was easy to download and all instructions clear and understandable. I commute a lot in my EV which requires a good network of quality chargers. Plugnow provides the best in class chargers in their app.</p>
                    <div className="cDetails">
                        <p className="uname">Nirmal Martin</p>
                        {/* <p className="desig">Founder @Zapp Mobility</p> */}
                    </div>
                </div>
            </SplideSlide>
        </SplideTrack>
        <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev" id='splidePrev'>Prev</button>
            <button className="splide__arrow splide__arrow--next" id='splideNext'>Next</button>
        </div>
    </Splide>
    <div className="sliderButtons">
        <button onClick={ButtonClickerPrev}><i className="fa-solid fa-angle-left"></i></button>
        <button onClick={ButtonClickerNext}><i className="fa-solid fa-angle-right"></i></button>
    </div>
    </>
  )
}

export default Testimonials