import React, { useEffect, useState } from 'react'
import Testimonials from './../components/Testimonials';
import ReCAPTCHA from 'react-google-recaptcha';

const Playstore = () => {
  window.location.href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.linuxmantra.plugnow"
}
const Home = () => {
  const [formData, setFormData] = useState({
    name: '',
    contactNumber: '',
    location: '',
    chargerCapacity: '',
    purpose: ''
  });
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  useEffect(()=> {
    let timer1 = setTimeout(() => {
      document.getElementById('carouselHeroNext').click();
    }, 5000);
    return () => {
      clearTimeout(timer1);
    };
  },[]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const onChangeRecaptcha = (token) => {
    setRecaptchaToken(token);
  };

  const onSubmitEvent = (event) => {
    event.preventDefault();

    if (!recaptchaToken) {
      alert('fill the reCAPTCHA');
      return;
    }
    setLoading(true)
    const scriptURL = 'https://script.google.com/macros/s/AKfycbxPo0-zjNDnQVai4pQIirfTZUrffQAcohzgz_gTs_fK2nIzL-URcyT07YmtJ4w86kTDKw/exec';
    let formDatas = new FormData();
    formDatas.append('Name', formData.name);
    formDatas.append('Contact Number', formData.contactNumber);
    formDatas.append('Location', formData.location);
    formDatas.append('Charger Capacity Required in kW', formData.chargerCapacity);
    formDatas.append('Purpose', formData.purpose);
    fetch(scriptURL, { method: 'POST', body: formDatas })
      .then(response => {
        console.log('Success!', response);
        setMessage('Thank you! Your message has been sent.');
        setFormData({
          name: '',
          contactNumber: '',
          location: '',
          chargerCapacity: '',
          purpose: ''
        });
        setLoading(false)
        window.location.href = window.location.href+'?status=success';
      })
      .catch(error => {
        console.error('Error!', error.message)
        setLoading(false)
      });
  }
  return (
    <main>
      <section id="heroSection">
        <div id="carouselHero" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-pause="false">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="/img/banner-1.jpg" className="d-block w-100" alt="Banner" />
            </div>
            <div className="carousel-item">
              <img src="/img/banner-2.jpg" className="d-block w-100" alt="Banner" />
            </div>
            <div className="carousel-item">
              <img src="/img/banner-3.jpg" className="d-block w-100" alt="Banner" />
            </div>
          </div>
          <div className="carouselText">
            <div className="container">
              <div className="row position-relative">
                <div className="col-md-10 col-lg-8 col-xl-6">
                  {/* <h2 className='outline white'>Solar</h2> */}
                  <h1>Building India's EV Charging Station Network</h1>
                  <p>PlugNow let's you monetize your EV charge point and lets you decide who can access it, when and what cost.</p>
                  <a href='#app' className='mainBtn white'>Download App</a>
                </div>
              </div>
            </div>
          </div>
          <button className="hideIt carousel-control-prev" type="button" data-bs-target="#carouselHero" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="hideIt carousel-control-next" type="button" data-bs-target="#carouselHero" data-bs-slide="next" id='carouselHeroNext'>
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
      <section id="usp">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-3">
              <img src="/img/usp-pic.jpg" alt="usp-pic" />
            </div> */}
            <div className="col-lg-12 d-grid align-items-center">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="usp"><i className="fa-solid fa-bolt"></i>
                    <div className="textContent">
                      <h4>99%</h4>
                      <p>successful charges</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="usp"><i className="fa-solid fa-plug"></i>
                    <div className="textContent">
                      <h4>20+</h4>
                      <p>charge points added</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="usp"><i className="fa-solid fa-user"></i>
                    <div className="textContent">
                      <h4>150+</h4>
                      <p>users onboarded</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="usp"><i className="fa-solid fa-battery-full"></i>
                    <div className="textContent">
                      <h4>1.5k+</h4>
                      <p>charges performed</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-6 position-relative">
              <img src="/img/about-img.jpg" alt="about_image" className="img-fluid" />
              <div className="infoByte">
                <h4>100+</h4>
                <p>eV Charging Daily</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sectionHead">
                <h2 className='outline white'>About</h2>
                <span>who we are</span>
              </div>
              <h3 className='smallHead'>The only all-in-one solution for all your EV charging needs</h3>
              <div className="innerSection">
                <h4>Home charging</h4>
                <p>Save on electricity costs, lower your CO2 emissions, and use green energy with PlugNow home charging. You also get access to a worldwide network of publicly available charge points.</p>
                {/* <a href="# ">Learn More</a> */}
              </div>
              <div className="innerSection">
                <h4>Business solutions</h4>
                <p>Control who has access to your charge points, when and at what cost. PlugNow provides a fully customizable EV charging solution to fit your business needs.</p>
                {/* <a href="# ">Learn More</a> */}
              </div>
              <div className="innerSection">
                <h4>Partner solutions</h4>
                <p>Grow your EV charging business with PlugNow's full-fledged solution to attract users and seamlessly manage usage, pricing, availability and transactions.</p>
                {/* <a href="# ">Learn More</a> */}
              </div>
              <a href="img/brochure.pdf " className="downloadBtn"  download><i className="fa-solid fa-arrow-down"></i><span>Download Brochure</span></a>
            </div>
          </div>
        </div>
      </section>
      <section id="why">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sectionHead">
                <h2 className='outline white'>Why Us</h2>
                <span>why choose us</span>
              </div>
            </div>
            <div className="col-lg-6">
              <h3 className='smallHead'>Join the fastest growing EV charging platform in Kerala</h3>
              <div className="innerSection bullet">
                <h4>Hardware agnostic</h4>
                <p>PlugNow works with the most popular charge point brands in the world. The rest is soon to follow. Easily integrate our software with your charge point to get access to all our EV charging features. </p>
              </div>
              <div className="innerSection bullet">
                <h4>Smart features</h4>
                <p>The PlugNow platform is packed with features developed for you to easily manage your charge points without having to incur any of the costs associated with developing a back-office, phone app, secure payment and management platform.</p>
              </div>
              <div className="innerSection bullet">
                <h4>Roaming</h4>
                <p>Put your charge points on the map for all EV drivers to see. With connections to all major roaming hubs as well as peer-to-peer networks, PlugNow provides unlimited charging possibilities for your customers.</p>
              </div>
              <div className="innerSection bullet">
                <h4>Complete package</h4>
                <p>Decide who can use your charge point, when and at what cost. Everything is fully customizable and can be tailored to you or your customers regardless of hardware. New smart features are launched regularly to help you stay ahead of industry shifts.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="/img/round-drawing.png" alt="round-drawing" className="img-decor" />
              <div className="fourSquare">
                <div className="half">
                  <div className="sqDiv text acnt-o">
                    <div className="tcontent">
                      <i className="fa-solid fa-solar-panel"></i>
                      <h4>Partner <br />with us</h4>
                      <div className="plus"><span></span></div>
                    </div>
                  </div>
                  <div className="sqDiv"><img src="/img/join-us-1.jpg" alt="why" /></div>
                </div>
                <div className="half">
                  <div className="sqDiv"><img src="/img/join-us-2.jpg" alt="why" /></div>
                  <div className="sqDiv text grey">
                    <div className="tcontent">
                      <i className="fa-solid fa-screwdriver-wrench"></i>
                      <h4>Monetize your <br />Parking Lot</h4>
                      <div className="plus"><span></span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="fact">
        <div className="container">
          <div className="row">
            {/* <div className="col-12">
              <h2 className="outline acnt">Points</h2>
            </div> */}
            <div className="col-lg-5">
              <div className="sectionHead">
                {/* <h2 className='outline dark'>Fact</h2> */}
                <span>Current Scenario</span>
              </div>
              <h3 className='smallHead'>See PlugNow in action</h3>
              <div className="innerSection">
                <h4>Many Independent EV  charging station providers</h4>
                <ul>
                  <li>4-5 apps for every EV user in every State</li>
                  <li>Maintaining balance in every app</li>
                </ul>
              </div>
              <div className="innerSection">
                <h4>Average Utilization of Public charging station is 5%. *</h4>
                <ul>
                  <li>Less utilization during night time</li>
                  <li>High Tariff compared to domestic charging</li>
                </ul>
              </div>
              <div className="innerSection">
                <h4>Inconvenience during EV charging &amp; waiting time.</h4>
                <ul>
                  <li>Prepaid scheduling of charging sessions</li>
                  <li>Private usage and billing for apartment owners</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-7 ps-lg-5 bl-1">
              {/* <img src="/img/kerala.svg" alt="kerala locations" className="img-fluid" /> */}
              <div className="row">
                <div className="col-12 ">
                  <div className="sectionHead">
                    {/* <h2 className="outline dark">Points</h2> */}
                    <span>Solution</span>
                  </div>
                  <h3 className='smallHead'>Our Solutions</h3>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-6">
                  <div className="innerSection p-0">
                    <h4>Private charging station can have a higher utilization.</h4>
                    <ul>
                      <li>A common platform for all chargers creates a uniform and better pricing.</li>
                      <li>Maximum usage during Night time by apartment owners/Hotel customers.</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-6">
                  <div className="innerSection p-0">
                    <h4>EV Charger App that shows all the chargers in your region</h4>
                    <ul>
                      <li>Partnering with thousands of independent charge point operators.</li>
                      <li>Creating a single payment portal</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-6">
                  <div className="innerSection p-0">
                    <h4>Location is near to home or office</h4>
                    <ul>
                      <li>Know how far you can go to nearest charger</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-6">
                  <div className="innerSection p-0">
                    <h4>More convenient and easy to schedule</h4>
                    <ul>
                      <li>Exclusive use for apartment owners and resort customers</li>
                      <li>OCPP 2.0</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-center py-4">
              <a href="https://www.virta.global/how-are-we-charging-a-deep-dive-into-the-ev-charging-station-utilization-rates">* https://www.virta.global/how-are-we-charging-a-deep-dive-into-the-ev-charging-station-utilization-rates</a>
            </div>
          </div>
        </div>
      </section>
      <section id="product">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sectionHead">
                <h2 className='outline dark opacity-50'>Product</h2>
                <span>Sustainable Transportation</span>
              </div>
            </div>
            <div className="col-lg-5 col-md-10">
              <h3 className='smallHead white'>About our Product</h3>
              <p className='lh-lg'>PlugNow is a tech based start-up aiming to aggregate EV charging stations across India and accelerate the transition to sustainable transportation.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="getintouch">
        <div className="container">
          <div className="overlayCard">
            <div className="row">
              <div className="col-xl-12 titleSection align-items-center d-grid"><h3 className='text-center'>Get in Touch with us</h3><i className="fa-solid fa-user-pen"></i></div>
              <div className="col-xl-12 formSection">
                <form className='zForm' onSubmit={onSubmitEvent}>
                  <div className="inpGrp"><input type="text" id='name' name='name' placeholder='Your Name*' value={formData.name} onChange={handleChange} required /></div>
                  <div className="inpGrp"><input type="tel" id='mob' name="contactNumber" placeholder='Mobile Number*' value={formData.contactNumber} onChange={handleChange} required /></div>
                  <div className="inpGrp"><input type="text" id='location' name="location" placeholder='Your Location*' value={formData.location} onChange={handleChange} required /></div>
                  <div className="inpGrp"><input type="text" id='location' name="chargerCapacity" placeholder='Charge Capacity required in kW*' value={formData.chargerCapacity} onChange={handleChange} required /></div>
                  <div className="inpGrp">
                    <select name="purpose" id="itype" value={formData.purpose} onChange={handleChange}>
                      <option value="" selected hidden disabled>Purpose</option>
                      <option value="commercial">Commercial</option>
                      <option value="private">Private use</option>
                    </select>
                  </div>
                  <div className="inpGrp">
                    <ReCAPTCHA
                      sitekey={recaptchaSiteKey}
                      onChange={onChangeRecaptcha}
                    />
                  </div>
                  {/* <div className="inpGrp"><input type="number" id='mob' placeholder='Monthly Electricity Bill Amount in INR*' required />
                  <small>If you are not getting a monthly bill, please calculate and enter amount for one month.</small>
                  </div> */}
                  <div className="btnGrp">{loading ? <div className='mainBtn acnt container text-center p-2'><div class="spinner-border text-muted"></div></div> : <button type="submit" className='mainBtn acnt'>Get it Now</button>}</div>
                  {message && <div class="alert alert-success alert-dismissible m-auto">{message}</div>}
                  <small className="disclaimer"><span>Disclaimer:</span> By submitting this lead form, you are giving explicit consent to Solx Green Energy LLP and its employees/ representatives to store your details in their database, use your contact details to get in touch with you regarding your interest, and also to send promotional emailers. You also agree that the explicit consent expressed by submitting this lead form overrides DO NOT DISTURB (DND) registry.</small>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="app">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sectionHead text-center">
                <h2 className="outline dark">App</h2>
                <span>PlugNow App</span>
              </div>
              <div className="smallHead text-center">Install PlugNow App now!</div>
            </div>
          </div>
          <div className="row appRow">
            <div className="col-md-6">
              <img src="/img/phone.png" alt="phone" className='img-fluid' />
            </div>
            <div className="col-md-6">
              <div className="centerDiv">
                <img src="/img/logo.jpg" alt="app icon" className="appIcon" />
                <div className="rating">
                  <div className="d-flex justify-content-between">
                    <i className="mx-2 fa-solid fa-star"></i>
                    <i className="mx-2 fa-solid fa-star"></i>
                    <i className="mx-2 fa-solid fa-star"></i>
                    <i className="mx-2 fa-solid fa-star"></i>
                    <i className=" mx-2 fa-solid fa-star-half-stroke"></i>
                  </div>
                  <p>Google Play Store</p>
                  <div className="number">4.5</div>
                </div>
                <button onClick={Playstore} className="playbutton mb-4"><img src="/img/google-play-badge.png" alt="playstore" className='img-fluid' /></button>
                <a href='https://apps.apple.com/in/app/plugnow/id1629450985' target={'_blank'} className="playbutton"><img src="/img/app-store.png" alt="playstore" className='img-fluid' /></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section id="solution">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-lg-5 mb-0 pb-5">
              <div className="sectionHead text-center mb-5">
                <h2 className="outline dark">Solution</h2>
                <span>Our Solution</span>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-6">
              <div className="innerSection p-0">
                <h4>Private charging station can have a higher utilization.</h4>
                <ul>
                  <li>A common platform for all chargers creates a uniform and better pricing.</li>
                  <li>Maximum usage during Night time by apartment owners/Hotel customers.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-6">
              <div className="innerSection p-0">
                <h4>EV Charger App that shows all the chargers in your region</h4>
                <ul>
                  <li>Partnering with thousands of independent charge point operators.</li>
                  <li>Creating a single payment portal</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-6">
              <div className="innerSection p-0">
                <h4>Location is near to home or office</h4>
                <ul>
                  <li>Know how far you can go to nearest charger</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-6">
              <div className="innerSection p-0">
                <h4>More convenient and easy to schedule</h4>
                <ul>
                  <li>Exclusive use for apartment owners and resort customers</li>
                  <li>OCPP 2.0</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section id="client">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 p-0 position-relative">
              <h2 className='outline dark'>Clients</h2>
              <img src="/img/client-pic.jpg" alt="client" />
            </div>
            <div className="col-lg-6 position-relative">
              <h2 className="outline dark down">say</h2>
              <div className="sectionHead"><span>some facts</span></div>
              <h3 className="smallHead white icon">Client Testimonials <i className="fa-solid fa-quote-left"></i></h3>
              <Testimonials />
            </div>
          </div>
        </div>
        <img src="/img/client-bg.jpg" alt="solar panel" className='tr img-fluid' />
        <img src="/img/client-drawing.png" alt="drawing" className='bl img-fluid' />
      </section>
      <section id="elevate">
        <div className="anchor">
          <div className="container">
            <div className="overlayCard">
              <h3>Elevate your EV charging experience</h3>
              <a href='#app' className="mainBtn white">Download App</a>
              <span><i class="fa-brands fa-google-play"></i></span>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Home