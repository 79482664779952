import React from 'react';
import NoPage from '../pages/NoPage';
import {
  Routes,
  Route,
} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Home from '../pages/Home';
import PrivacyPolicy from '../pages/PrivacyPolicy';

const Layout = () => {
  return (
    <>
    <Header />
      <Routes>
        <Route exact path="" element={<Home />}></Route>
        <Route exact path="/privacypolicy" element={<PrivacyPolicy />}></Route>
        <Route path='*' element={<NoPage />}></Route>
      </Routes>
      <Footer />
    </>
  )
};

export default Layout;