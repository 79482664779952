import React from 'react'
import { Link } from 'react-router-dom'

window.onscroll = function () {
  const mainNav = document.getElementById('mainNav');

  if (mainNav) {
    if (window.pageYOffset > 200) {
      mainNav.classList.add("scrolled");
    }
    else {
      mainNav.classList.remove("scrolled");
    }
  }
}
const SideToggle = () => {
  document.querySelector(".sideBar").classList.toggle("active");
}
const MobMenuToggle = () => {
  document.querySelector(".mobileBtn").classList.toggle("active");
  document.querySelector(".mobsideBar").classList.toggle("active");
}
const Header = () => {
  if (window.innerWidth > 1199) {
    return (
      <>
        <header id="mainNav">
          <nav>
            {/* <div className="topSectionNav">
              <ul className="contact hList">
                <li>27 Division St, New York, NY 10002</li>
                <li><a href="# ">contact@email.com</a></li>
                <li><a className='phone' href="tel:">+1 (800) 123 456 789</a></li>
              </ul>
              <div className="rightSection">
                <ul className='navLinks hList'>
                  <li><a href="# ">Career</a></li>
                  <li><a href="# ">News &amp; Media</a></li>
                  <li><a href="# ">FAQ</a></li>
                </ul>
                <ul className="socialLinks hList">
                  <li><a href="# "><i className="fa-brands fa-twitter"></i></a></li>
                  <li><a href="# "><i className="fa-brands fa-facebook-f"></i></a></li>
                  <li><a href="# "><i className="fa-brands fa-linkedin-in"></i></a></li>
                  <li><a href="# "><i className="fa-brands fa-instagram"></i></a></li>
                </ul>
              </div>
            </div> */}
            <div className="bottomSectionNav">
              <div className="logo"><Link to={"/"} className='navBrand'><img src="/img/logo.jpg" alt="Logo" /></Link></div>
              <ul className="headerNavList hList">
                <li><a href='/#heroSection'>Home</a></li>
                <li><a href="/#about">About Us</a></li>
                <li><a href="/#why">Why Us</a></li>
                <li><a href="/#fact">Features</a></li>
                <li><a href="/#getintouch">Contact Us</a></li>
              </ul>
              <div className="lastSection">
                {/* <a href="# " className="zBtn sec">Login/ Sign Up</a> */}
                {/* <ul className="ecomAction hList">
                  <li><a href="# "><i className='fa-solid fa-cart-shopping'></i></a></li>
                  <li><a href="# "><i className='fa-solid fa-magnifying-glass'></i></a></li>
                </ul> */}
                <button className="menuBtn" onClick={SideToggle}><span></span><span></span><span></span></button>
              </div>
            </div>
          </nav>
        </header>
        <div className="sideBar">
          <div className="sideBarOverlay"></div>
          <div className="sideBarContent">
            <button className="sideBarClose" onClick={SideToggle}><span></span><span></span></button>
            <img src="/img/logo.jpg" alt="logo" className="sideLogo" />
            <p className="lh-lg">PlugNow let's you monetize your EV charge point and lets you decide who can access it, when and what cost.</p>
            <h3>Contact</h3>
            <ul className="addressList">
              <li>
                <i className="fa-solid fa-location-dot"></i>
                <div className="textContent">
                  Ernakulam, Kerala, India
                </div>
              </li>
              <li>
                <i className="fa-solid fa-envelope"></i>
                <div className="textContent">
                  <a href="mailto:contact@plugnow.in">contact@plugnow.in</a>
                  {/* <br />
                  <a href="mailto:nikhil.joy@plugnow.in">nikhil.joy@plugnow.in</a> */}
                </div>
              </li>
              <li>
                <i className="fa-solid fa-phone"></i>
                <div className="textContent">
                  {/* <a href="tel:+919999999999">+91 999 999 9999</a><br /> */}
                  {/* <a href="tel:+919493547077">+91 9493547077</a> */}
                  <a href="tel:+918590058412">+91 8590058412</a>
                  <a href="tel:+919061407794">+91 9061407794</a>
                </div>
              </li>
            </ul>
            {/* <ul className="socialLinks">
              <li><a href="# "><i className="fa-brands fa-twitter"></i></a></li>
              <li><a href="# "><i className="fa-brands fa-facebook-f"></i></a></li>
              <li><a href="# "><i className="fa-brands fa-linkedin-in"></i></a></li>
              <li><a href="# "><i className="fa-brands fa-instagram"></i></a></li>
            </ul> */}
          </div>
        </div>
      </>
    )
  }
  else {
    return (
      <>
        <header id="mobNav">
          <nav>
            <button className="mobileBtn" onClick={MobMenuToggle} >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <a href="# " className="navBrand"><img src="/img/logo.jpg" alt="logo" /></a>
          </nav>
        </header>
        <div className="mobsideBar">
          <div className="content">
            <img src="/img/logo.jpg" alt="logo" className='sideLogo' />
            <ul className="headerNavList">
              <li><a onClick={MobMenuToggle} href="/#heroSection">Home</a></li>
              <li><a onClick={MobMenuToggle} href="/#about">About Us</a></li>
              <li><a onClick={MobMenuToggle} href="/#why">Why Us</a></li>
              <li><a onClick={MobMenuToggle} href="/#fact">Features</a></li>
              <li><a onClick={MobMenuToggle} href="/#getintouch">Contact Us</a></li>
            </ul>
            {/* <ul className="socialLinks hList">
              <li><a href="# "><i className="fa-brands fa-twitter"></i></a></li>
              <li><a href="# "><i className="fa-brands fa-facebook-f"></i></a></li>
              <li><a href="# "><i className="fa-brands fa-linkedin-in"></i></a></li>
              <li><a href="# "><i className="fa-brands fa-instagram"></i></a></li>
            </ul> */}
          </div>
        </div>
      </>
    )
  }
}

export default Header