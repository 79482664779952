import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img src="/img/logo.jpg" alt="Logo" className="footerLogo" />
            <p className="lh-lg">Decide who can use your charge points, when and at what cost. Plugnow’s platform lets you customise your EV charge points to your EV charging needs.</p>
            {/* <ul className="socialLinks">
              <li><a href="# "><i className="fa-brands fa-twitter"></i></a></li>
              <li><a href="# "><i className="fa-brands fa-facebook-f"></i></a></li>
              <li><a href="# "><i className="fa-brands fa-linkedin-in"></i></a></li>
              <li><a href="# "><i className="fa-brands fa-instagram"></i></a></li>
            </ul> */}
          </div>
          <div className="col-md-4 otherSections">
            <h3>Contact</h3>
            <ul className="addressList">
              <li>
              <i className="fa-solid fa-phone"></i>
                <div className="textContent">
                  {/* <a href="tel:+919493547077">+91 9493547077</a> */}
                  <a href="tel:+918590058412">+91 8590058412</a>
                  <a href="tel:+919061407794">+91 9061407794</a>
                  {/* <br/>
                  <a href="tel:+919999999999">+91 999 999 9999</a> */}
                  </div>
                </li>
              <li>
                <i className="fa-solid fa-location-dot"></i>
                <div className="textContent">
                  Ernakulam, Kerala, India
                  </div>
              </li>
              <li>
                <i className="fa-solid fa-envelope"></i>
                <div className="textContent">
                  <a href="mailto:contact@plugnow.in">contact@plugnow.in</a>
                  {/* <br/>
                  <a href="mailto:nikhil.joy@plugnow.in">nikhil.joy@plugnow.in</a> */}
                  </div>
                </li>
            </ul>
          </div>
          <div className="col-md-4 otherSections">
            <h3>Quick Links</h3>
            <ul className="pageLinks">
              <li><a href="/#heroSection">Home</a></li>
              <li><a href="/#about">About</a></li>
              <li><a href="/#why">Why Us</a></li>
              <li><a href="/#product">Product</a></li>
              <li><a href="/#getintouch">Get in Touch</a></li>
              <li><a href="/#app">App</a></li>
              <li><a href="/#client">Testimonial</a></li>
              <li><Link to="/privacypolicy">Privacy Policy</Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bottomFooter">
        <div className="container py-4 d-flex justify-content-between">
          <p className="mb-0">Copyright &copy; {(new Date().getFullYear())} Plugnow | All Right Reserved</p>
          <p className="mb-0">Developed by <a href="https://webohash.com/" target='_blank'>Webohash</a></p>
        </div>
      </div>
    </footer>

    <div class="d-none d-md-block">
      <a href="#heroSection" class="mainBtn active" id="ctaBtn">Contact Our Experts</a>
      <a href="#home" id="upBtn" class="active"><i class="fa-solid fa-arrow-up"></i></a>
    </div>
    <div class="d-block d-md-none">
      <ul class="bottombar">
        <li>
          <a href="#getintouch">
            <div class="icon"><i class="fa-solid fa-envelope"></i></div>
            <div class="text">Contact Our Experts</div>
          </a>
        </li>
        <li>
          <a href="tel:+918590058412">
            <div class="icon"><i class="fa-solid fa-phone"></i></div>
            <div class="text">Talk to us.</div>
          </a>
        </li>
      </ul>
    </div>
    </>
  )
}

export default Footer